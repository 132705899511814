$primary: #005E5D;
$secondary: #D6E85F;
$secondary-text-color: $primary;

$font-family-base: 'CorporateS';
$header-logo-url: url('../img/logo-rvk.svg');

@mixin font-include() {
  @import '../fonts/rvk.css';
}
@import "~bootstrap/scss/functions";
//see _variables.scss for all possible bootstrap variables
@import "~bootstrap/scss/mixins/container";
@import "~bootstrap/scss/mixins/transition";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/gradients";
@import "~bootstrap/scss/mixins/box-shadow";
@import "~bootstrap/scss/mixins/buttons";
@import "~bootstrap/scss/mixins/forms";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/vendor/rfs";

$enable-shadows: false;

$primary: #333 !default;
$secondary: #CCC !default;
$secondary-text-color: #FFFFFF !default;
$danger: #D69C9C !default;
$header-logo-url: url('../img/logo-rvk.svg') !default;
$grid-gutter-width: 4rem;

$body-color: $primary !default;
$banner-bg-color: $primary !default;
$banner-text-color: $secondary-text-color !default;
$btn-box-shadow: none !default;

$toggle-active-background-color: $primary !default;
$toggle-active-text-color: $secondary !default;

$sort-background-color: $primary !default;
$sort-text-color: #FFF !default;

$font-size-base: 1.6rem !default;
$font-size-sm: 1.4rem !default;

$header-text-transform: uppercase !default;
$title-font-family: adobe-caslon-pro,Times New Roman,Times,serif !default;

// map options
$map-kanton-enable: false !default;
$map-kanton-fill-color: #E5E3DF !default;
$map-country-opacity: 0.8 !default;
$map-kanton-disabled-color: $primary !default;
$map-kanton-disabled-opacity: 0.2 !default;
$map-center-fit-bounds: true !default;
$map-radius: 180 !default;

////// dropdown
$dropdown-bg: white !default;
$dropdown-link-active-bg: white !default;
$dropdown-link-active-color: #DEDEDE !default;
$dropdown-link-hover-bg: $primary !default;
$dropdown-link-hover-color: white !default;

////// modal
$modal-lg: 960px !default;
$modal-content-border-radius: 0 !default;
$modal-inner-padding: 3rem !default;

///// forms
$form-floating-height: 4.2rem !default;
//$form-floating-line-height: 1 !default;
$form-floating-padding-y: 1rem !default;
$form-floating-padding-x: 1rem !default;
$form-floating-input-padding-t: .75rem !default;
$form-floating-label-opacity: 0.5 !default;
$form-floating-label-transform: scale(.85) translateY(-1rem) translateX(.5rem) !default;

$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$primary}' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z'/></svg>");
$form-select-feedback-icon-size: 24px 20px;
$form-check-input-border: 1px solid $primary;

$input-btn-focus-width: 0;
$input-btn-padding-y: 0.5rem;

$border-radius: 1rem !default;
$border-radius-lg: 1.5rem !default;
$dropdown-min-width: 0;

////// pagination
$pagination-border-radius: 0;
$pagination-border-width: 0;
$pagination-active-bg: #F5F8E7;
$pagination-active-color: $primary;

///// modal
$modal-header-border-width: 0;

@import "~bootstrap/scss/_variables";
@if mixin-exists(font-include) {
  @include font-include();
}

:root {
  --secondary-text-color: #{$secondary-text-color};
  --map-country-opacity: #{$map-country-opacity};
  --map-radius: #{$map-radius};
  --map-enable-kanton: #{$map-kanton-enable};
  --map-kanton-fill-color: #{$map-kanton-fill-color};
  --map-kanton-disabled-color: #{$map-kanton-disabled-color};
  --map-kanton-disabled-opacity: #{$map-kanton-disabled-opacity};
  --map-center-fit-bounds: #{$map-center-fit-bounds};
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  background-color: white;
  overflow-y: scroll;

  @import "common.scss";
  @import "header.scss";
  @import "footer.scss";

  #map-container, #list-container {
    position: relative;

    .spinner-border {
      display: none;
      position: absolute;
      z-index: 1010;
      left: 50%;
      margin-left: $spinner-width / -2;
      top: 50%;
      margin-top: $spinner-height / -2;
      color: $primary;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .25);
      z-index: 20;
      display: none;
    }

    &.loading {
      .spinner-border {
        display: block;
      }

      &:after {
        display: block;
      }
    }
  }

  #map-container {
    height: calc(100vh - 14rem);
    height: calc(calc(var(--vh, 1vh) * 100) - 14rem);
    min-height: 100%;
    width: 100%;

    #map {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    #geolocate {
      position: absolute;
      right: 6rem;
      bottom: 3rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: $white;
      background-image: url(../img/geolocation.png);
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1010;
    }
  }

  //.kanton-label {
  //  background-color: $map-kanton-fill-color;
  //}

  .row.main {
    .col-center {
      flex: 1;
      width: auto;
    }

    .col-right {
      flex: 0 0 34rem;
      width: 34rem;
    }
  }

  #list-container {
    margin-left: 2rem;
    height: 100%;

    .spinner-border {
      top: 7rem;
      color: $white;
    }

    ul.list {
      padding-left: 0;
    }
  }

  #filter-container {

    .banner-container {
      margin: 5rem 5rem 2rem;
      display: flex;
      justify-content: center;
    }

    .banner {
      background: $banner-bg-color;
      color: $banner-text-color;
      max-width: 20rem;
    }

    .banner-content {
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      hyphens: auto;
      text-align: center;
      font-size: 1.2rem;

      a {
        display: block;
        color: inherit;
        text-decoration: none;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767.98px) and (orientation: portrait) {
    header {
      .logo-container {
        padding: 0;

        .logo {
          background-position: center right;
        }
      }

      .row.main {
        --bs-gutter-x: 1rem;

        .title {
          margin-left: 0;
        }

        .col-right {
          flex: 1;
          width: auto;
          height: 7rem;
        }
      }
    }
    main {
      .row.main {
        flex-direction: column;

        .col-center {
          order: 2;
        }

        .col-right {
          order: 1;
          flex: 1;
          width: auto;
        }
      }
    }
  }

  @media (min-width: 767.98px), (orientation: landscape) {
    #filter-container {
      display: block;
    }
    a[href="#filter-container"] {
      display: none;
    }
  }

  @media (max-width: 767.98px) and (orientation: landscape) {
    .row.main {
      --bs-gutter-x: 1rem;

      .col-right {
        flex: 0 0 25rem;
        width: 25rem;
      }
    }
  }


  #langtoggle {
    color: $primary;

    &:after {
      display: none;
    }
  }

  #sort {
    border: .1rem solid $sort-background-color;
    padding: 3rem;
    margin-bottom: 1rem;
    //margin-left: 2rem;
    background: $sort-background-color;
    color: $sort-text-color;

    a {
      color: inherit;
    }

    @include media-breakpoint-down(lg) {
      padding: 2rem;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    [data-sort-list] {
      cursor: pointer;
    }
  }

  #mainlist {
    ul.list {
      list-style-type: none;
      //padding: 0;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }

      li {
        display: block;
        border: 1px solid $primary;
        padding: 3rem;
        @include media-breakpoint-down(lg) {
          padding: 2rem;
        }

        //.row {
        //  --bs-gutter-x: 3rem;
        //}

        .bi-geo-alt a {
          cursor: pointer;
        }

        //  margin-left: -3rem;
        //  margin-right: -3rem;
        //  >div {
        //    padding-left: 3rem;
        //    padding-right: 3rem;
        //  }
        //}
        margin-bottom: 1rem;

        .detail-map {
          margin-top: 3rem;
          height: 21rem;
          @include media-breakpoint-down(md) {
            margin-top: 2rem;
            margin-bottom: -2rem;
          }
        }

        [class*="bi-"]:before {
          width: 2.5rem;
        }

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #emptylist {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bi {
      font-size: 5rem;
    }
  }

  @if mixin-exists(body) {
    @include body();
  }

  @media print {
    #mainlist {
      ul.list {
        li {
          page-break-inside: avoid;
          padding: 1rem;

          .col-lg-6 {
            flex: 0 0 auto;
            width: 50%;
            margin: 0;
            height: auto !important;
          }

          .border-left-secondary {
            border: none;
          }

        }
      }
    }

  }

}