
footer {
  color: $white;
  @include font-size($font-size-sm);

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  a {
    color: inherit;
  }

  .kantone {
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li {
      display: inline-block;

      &:after {
        content: ' / ';
      }

      &:last-child:after {
        content: '';
      }
    }
  }
}

