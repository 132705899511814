
.bg-dark {
  a {
    color: inherit;
    text-decoration: none;
    margin-left: 2rem;
  }
}

.ss-content .ss-list .ss-option:not(.ss-disabled) {
  &:hover, &.ss-highlighted {
    background-color: $dropdown-link-hover-bg;
    color: $dropdown-link-hover-color;
  }
}

.modal {
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .modal-header {
    padding-bottom: 0;
  }

  [class*="bi-"]:before {
    width: 2.5rem;
    margin: .75rem 0;
  }

  .map {
    width: 100%;
    height: 100%;
  }
}

.toggle {
  border-radius: $border-radius;
  border: .1rem solid $primary;
  display: flex;
  overflow: hidden;

  > .toggle-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: pointer;

    @include transition();

    &:hover:not(.active) {
      background: rgba($toggle-active-background-color, .25);
    }
  }

  .active {
    background: $toggle-active-background-color;
    color: $toggle-active-text-color;
  }

  input[type=radio] {
    display: none;
  }

  input:checked + label {
    background: $toggle-active-background-color !important;
    color: $toggle-active-text-color !important;
  }

  &.small {
    border-radius: $border-radius*4;
    overflow: hidden;

    > .toggle-item {
      padding: 0 2rem;
      width: 3rem;
    }
  }
}

.small-gutter {
  --bs-gutter-x: 1rem;
}

a[onclick] {
  cursor: pointer;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control, .form-select {
  border-color: $primary;

  &::placeholder {
    color: $body-color;
  }

  &:hover {
    box-shadow: $input-btn-focus-box-shadow;
  }
}

.form-floating {
  label {
    color: $body-color;
    opacity: $form-floating-label-opacity;
  }

  .form-control:focus ~ label, > .form-control:not(:placeholder-shown) ~ label, .form-select:not(.empty) ~ label {
    background: $white;
    padding: 0 .25rem;
    height: auto;
    line-height: 1;
    opacity: 1;
  }

  .form-select {

    &.empty {
      ~ label {
        transform: none;
        opacity: $form-floating-label-opacity;
      }
    }
  }
}

.border-radius-lg {
  border-radius: $border-radius-lg*2;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

@if variable-exists("secondary-text-color") {
  .btn-secondary {
    color: $secondary-text-color;
  }
}

.btn-secondary {
  @if variable-exists("secondary-hover-bg-color") {
    &:hover {
      background-color: $secondary-hover-bg-color;
      border-color: $secondary-hover-bg-color;
    }
  }
}

.border-left-secondary {
  border-left: 1px solid $secondary;
  @include media-breakpoint-down(xl) {
    border-left: none;
    //border-top: 1px solid $secondary;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}

.scrolltofixed {
  &.sticky {
    position: sticky;
    top: .5rem;
    @include media-breakpoint-down(md) {
      position: relative;
    }
  }
}