
header {
  .col-right, .col-center {
    height: 14rem;

    @include media-breakpoint-down(lg) {
      height: 10rem;
    }

    @include media-breakpoint-down(md) {
      height: 7rem;
    }
  }

  .logo-container {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
  }

  .logo {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 24rem;
    max-height: 9rem;
    background-image: $header-logo-url;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .title {
    margin-left: 2rem;
    text-transform: $header-text-transform;
    color: $primary;
    white-space: nowrap;
    text-decoration: none;

    font-size: 4.5rem;
    @include media-breakpoint-up(xl) {
      font-size: 6.5rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 3rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
      margin-left: 0;
    }
  }
}